import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <Text classes="copyright-txt" styles={{ color: "white" }}>
                    FOREMED LIFESCIENCES PVT LTD
                  </Text>
                </Link>

                <Text classes="copyright-txt" styles={{ color: "white" }}>
                  &copy; {new Date().getFullYear()}. All Rights Reserved.
                </Text>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Information" styles={{ color: "white" }}>
              <List classes="widget-list">
                <LI>
                  <Link
                    to={`${process.env.PUBLIC_URL + "/about"}`}
                    style={{ color: "white" }}
                  >
                    Our company
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/contact"}`}>
                    Contact us
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/services"}`}>
                    Our services
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/careers"}`}>
                    Career
                  </Link>
                </LI>
              </List>
            </Widget>
          </div>

          {/* Uncomment and adjust the social links section if needed */}
          {/* <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Social Links">
              <List classes="widget-list">
                <LI><Link to="https://facebook.com/hasthemes/" target={'_blank'}>Facebook</Link></LI>
                <LI><Link to="https://twitter.com/hasthemes/" target={'_blank'}>Twitter</Link></LI>
                <LI><Link to="https://dribbble.com/hastech/" target={'_blank'}>Dribbble</Link></LI>
                <LI><Link to="https://www.instagram.com/" target={'_blank'}>Instagram</Link></LI>
              </List>
            </Widget>
          </div> */}

          <div className="col-md-4 col-lg-3">
            <Widget title="Contact Us">
              <address>
                2nd floor, R R Chandra residency, MIG 187, phase 1, road no 1,
                KPHB Colony, Hyderabad, Telangana 500072 <br />
                +91 6309128874 <br />
                <a href="mailto:Info@foremedlifesciences.com">
                  Info@foremedlifesciences.com
                </a>
              </address>
            </Widget>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
